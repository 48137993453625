import { saveToStorage } from 'App/Helpers'
import {
	LOGOUT,
	SET_ACTIVE,
	SET_SESSION,
	SET_USER,
	SET_VERSION,
} from './mutation-types'
import Application from 'App/Foundation/Application'

/**
 * Export the Office Manager Vuex store plugin.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default store => {
	// Save the active partner id to local storage if it is not persisted
	if (store.state.partners.active && !localStorage.getItem('partner')) {
		saveToStorage('partner', store.state.partners.active)
	}

	store.subscribe(({ type, payload }) => {
		switch (type) {
			case `auth/${LOGOUT}`:
				Application.getInstance().emit('partner:change', {
					leave: store.state.partners.active,
					subscribe: 0,
				})
				localStorage.removeItem('partner')
				localStorage.removeItem('session')
				localStorage.removeItem('user')

				store.dispatch('calls/reset')
				store.dispatch('partners/reset')
				break

			case `auth/${SET_USER}`:
				saveToStorage('user', payload)

				break

			case `auth/${SET_SESSION}`:
				saveToStorage('session', payload)
				break

			case `auth/${SET_VERSION}`:
				saveToStorage('version', payload)
				break

			case `partners/${SET_ACTIVE}`:
				store.dispatch('calls/reset')
				store.dispatch('pages/reset')
				store.dispatch('callTypes/reset')
				store.dispatch('providers/reset')
				store.dispatch('calendars/reset')
				store.dispatch('events/reset')
				store.dispatch('timeBlocks/reset')

				payload
					? saveToStorage('partner', payload)
					: localStorage.removeItem('partner')

				break
		}
	})
}
